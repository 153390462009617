.footerContainer{
  a{
    color:white;
    &:hover{
      color:#EC9603;
    }
  }
}
.footerContainerLight{

  div{
    color:#0C2E40
  }
}

.kader{
  display: flex;
  justify-content: center;
}
